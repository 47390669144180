<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading">
    <v-row no-gutters class="text-center" wrap align="center">
      <v-col
        cols="12"
        sm="12"
      >
        <v-card style="box-shadow: none;">
        <v-card-text>
          <p class="primary--text" v-show="hayCambiosExamenes" style="margin-bottom: -2px;">Los campos base de algunos exámenes de este informe han cambiado <a class="text-decoration-underline" @click="actualizarCambiosExamenes">Actualizar</a> - <a class="text-decoration-underline" @click="dialogInfoActExamenes = true">Más Información</a></p>
          <p class="display-2 primary--text font-weight-regular">{{titulo}} - {{datosBaseInput[0].examen}}</p>
          <p class="display-1 primary--text font-weight-regular" style="margin-top: -15px;">Paciente: {{datosBaseInput[0].paciente}}</p>
          <p class="headline primary--text font-weight-regular mb-6" style="margin-top: -15px;">Estado: {{datosBaseInput[0].estado}}</p>
          <p class="title primary--text font-weight-regular mb-6" style="margin-top: -25px;">Ingreso: {{datosBaseInput[0].fcreacion}}</p>
          <p class="title primary--text font-weight-regular mb-10" style="margin-top: -25px;">Cambiar a
            <v-btn color="primary" class="mr-2" @click="toggleTipoInforme">INFORME {{tipoInformeBoton}}</v-btn>
            <v-btn class="mr-2" :color="colorBotonEdicion" @click="toggleEdicionCampos">{{modoEdicion}}</v-btn>
            <Transition name="fade"><v-btn v-show="verEditorExamenes" color="success" @click="dialogExamenes = true">EXAMENES</v-btn></Transition>
          </p>
          <v-form ref="form" v-model="valid">
            <v-row class="mb-5 pl-7 pr-7" v-show="formnormal">
              <v-col
                cols="12"
                sm="12"
              >
                <v-row class="ml-5">
                  <v-col cols="12" sm="4" v-for="item in resultados" v-bind:key="item.id">
                    <v-row>
                      <v-col cols="12" sm="10">
                        <v-combobox
                          :label="item.par"
                          :items="item.resauto.map(auto => auto.res)"
                          v-model="item.res"
                          counter
                          maxlength="100"
                          :rules="[requerido]"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <Transition name="fade">
                        <v-btn
                          color="error"
                          dark
                          fab
                          small
                          v-show="verQuitaCampo"
                          class="mt-3"
                          @click="preQuitarCampo(item.id)"
                        >
                          <v-icon dark>
                            mdi-close
                          </v-icon>
                        </v-btn>
                        </Transition>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mb-1 ml-10 mr-10" v-show="formdocumento">
              <v-col
                cols="12"
                sm="12"
              >
                <v-file-input
                  :rules="reglaUpload"
                  accept="document/pdf"
                  :placeholder="placedocumento"
                  prepend-icon="mdi-file-document"
                  show-size
                  @change="preDocumentoUpload"
                  @click:clear="cancelaDoc"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mb-6">
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-btn
                color="primary"
                @click="limpiar"
                class="mr-1 mb-1"
              >
              LIMPIAR
              </v-btn>
              <v-btn
                color="primary"
                @click="guardarSolo"
                class="mr-1 mb-1"
                :disabled="!valid"
              >
              GUARDAR
              </v-btn>
              <v-btn
                color="primary"
                @click="guardarImprimir"
                class="mr-1 mb-1"
                :disabled="!valid"
              >
              GUARDAR E IMPRIMIR
              </v-btn>
              <v-btn
                color="primary"
                @click="guardarEmail"
                class="mr-1 mb-1"
                :disabled="!valid"
              >
              GUARDAR Y ENVIAR POR EMAIL
              </v-btn>
              <v-btn
                color="primary"
                @click="guardarYSiguiente"
                class="mb-1"
                :disabled="disableSiguiente"
              >
              GUARDAR Y SIGUIENTE
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <VerImprimirInforme :idinforme.sync="this.idinforme" :impdirecto.sync="impresionDirecta"/>
    <EmailInformes :idinforme.sync="this.$store.state.ids" :enviardirecto.sync="enviarEmailDirecto"/>
    <DialogSimple
      :dialog.sync="dialogInfoActExamenes"
      :width="450"
    >
      <v-row no-gutters class="text-center" wrap align="center">
        <v-col
          cols="12"
          sm="12"
        >
          <v-card style="box-shadow: none;">
          <v-card-text>
            <p class="primary--text p-12">Algunos campos de exámenes de este informe han sido cambiados al editar examen. La opción Actualizar aplicará los cambios al presente informe. Se recomienda actualizar en el caso de que sea un informe reciente en donde se haya tenido que editar campos de exámenes a modo de corrección. No se recomienda actualizar cuando el informe ya se encuentre emitido, a no ser que sea parte de una corrección de campos.</p>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </DialogSimple>
    <DialogSimple
      :dialog.sync="dialogExamenes"
      :width="350"
    >
      <SelectCrud
        :items="$store.state.examenes"
        store="cambiarExamenes"
        metodo="cargarListaExamenes"
        :model.sync="examen"
        api_carga="examenes/listaexamenessinplantillas"
        :dialog.sync="dialogExamen"
        label="Examen"
        :modo.sync="modoExamen"
        nnuevo="NUEVO"
        :filtrar.sync="filtrarExamenes"
        api_filtro="examenes/listaexamenessinplantillas">
        <Examen :modo.sync="modoExamen" :dialogE.sync="dialogExamen" :nexamen.sync="examen" :origen="origenExamen"/>
      </SelectCrud>
      <v-row justify="center">
        <v-btn color="primary" @click="agregarExamen()">AGREGAR CAMPO</v-btn>
      </v-row>
    </DialogSimple>
  </DialogVentana>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'
import VerImprimirInforme from '@/components/verimprimirinforme.vue'
import EmailInformes from '@/components/emailinformes.vue'
import DialogSimple from '@/components/dialogsimple.vue'
import DialogVentana from '@/components/dialogventana.vue'
import SelectCrud from '@/components/selectcrud.vue'
import Examen from '@/views/examen.vue'

export default {
  data: () => ({
    titulo: 'Informar Exámen',
    resultados: [],
    valid: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    loading: false,
    tipoimpenv: '',
    idsInformeOrden: [],
    posInformesOrden: 0,
    disableSiguiente: false,
    idinforme: 0,
    impresionDirecta: false,
    enviarEmailDirecto: false,
    hayCambiosExamenes: false,
    dialogInfoActExamenes: false,
    botonestipoinforme: [
      { id: 1, nombre: 'Normal', color: 'primary', accion: 'informeNormal' },
      { id: 2, nombre: 'Documento', color: 'primary', accion: 'informeDocumento' }
    ],
    tipoinforme: '',
    reglaUpload: [
      value => !value || value.size < 2000000 || 'El tamaño del documento para informar debe ser menor a 2 MB'
    ],
    formnormal: true,
    formdocumento: false,
    tipoInformeBoton: 'Documento',
    nombredocumento: '',
    placedocumento: 'Seleccione su documento PDF para guardar como informe...',
    verQuitaCampo: false,
    modoEdicion: 'Modo Editar Campos',
    colorBotonEdicion: 'primary',
    verEditorExamenes: false,
    examen: '',
    dialogExamenes: false,
    dialogExamen: false,
    modoExamen: 'NUEVO',
    origenExamen: 'select',
    filtrarExamenes: false
  }),
  mixins: [
    Globales
  ],
  components: {
    VerImprimirInforme,
    EmailInformes,
    DialogSimple,
    DialogVentana,
    SelectCrud,
    Examen
  },
  props: ['dialogIE', 'datosBase'],
  methods: {
    reset () {
      this.resultados = []
      this.tipoimpenv = ''
      this.idsInformeOrden = []
      this.posInformesOrden = 0
      this.disableSiguiente = false
      this.idinforme = 0
      this.verQuitaCampo = false
      this.modoEdicion = 'Modo Editar Campos'
      this.colorBotonEdicion = 'primary'
      this.verEditorExamenes = false
    },
    toggleTipoInforme () {
      if (this.tipoInformeBoton === 'Documento') {
        this.informeDoc()
      } else if (this.tipoInformeBoton === 'Normal') {
        this.informeNormal()
      }
    },
    toggleEdicionCampos () {
      if (this.modoEdicion === 'Modo Editar Campos') {
        this.colorBotonEdicion = 'error'
        this.verQuitaCampo = true
        this.verEditorExamenes = true
        this.modoEdicion = 'Cerrar Editar Campos'
      } else if (this.modoEdicion === 'Cerrar Editar Campos') {
        this.colorBotonEdicion = 'primary'
        this.verQuitaCampo = false
        this.verEditorExamenes = false
        this.modoEdicion = 'Modo Editar Campos'
      }
    },
    informeNormal () {
      this.formnormal = true
      this.formdocumento = false
      this.tipoInformeBoton = 'Documento'
      this.tipoinforme = 'Normal'
      this.disableSiguiente = false
    },
    informeDoc () {
      this.formnormal = false
      this.formdocumento = true
      this.tipoInformeBoton = 'Normal'
      this.tipoinforme = 'Documento'
      this.disableSiguiente = true
    },
    preDocumentoUpload () {
      if (event.target.files.length > 0) {
        this.nombredocumento = event.target.files[0]
        this.valid = true
      }
    },
    cancelaDoc () {
      this.valid = false
    },
    async cargarDatosBase () {
      this.loading = true
      this.placedocumento = 'Seleccione su documento PDF para guardar como informe...'
      this.nombredocumento = ''
      const response = await ApiCon.get1('exameninformes/datosbase', this.idinforme)
      if (response.status === 200) {
        this.datosBaseInput[0].examen = response.data.examen
        this.datosBaseInput[0].estado = response.data.estado
        this.datosBaseInput[0].fcreacion = response.data.fecha
        this.tipoinforme = response.data.tipoinforme
        // traer campo tipoguardado para definir la modalidad en que se muestra la edición del informe.
        if (this.tipoinforme === 'Normal') {
          this.informeNormal()
        } else if (this.tipoinforme === 'Documento') {
          this.informeDoc()
          this.placedocumento = response.data.documento
        } else {
          this.tipoinforme = 'Normal'
          this.informeNormal()
        }
        // luego en cargar resultados si es Documento se muestra mensaje de que ya existe un doc y se muestra botón para VER INFORME.
      } else { alert('Error en cargarDatosBase...') }
      this.loading = false
    },
    async cargarInformesOrden () {
      this.loading = true
      const response = await ApiCon.get3('exameninformes/idsinformesorden', this.datosBase[0].norden, this.idinforme, this.$session.get('usuario'))
      if (response.status === 200) {
        this.idsInformeOrden = response.data
        if (this.idsInformeOrden.length === 0) {
          this.disableSiguiente = true
        }
      } else { alert('Error en cargarInformesOrden...') }
      this.loading = false
    },
    async cargarResultados () {
      this.loading = true
      const response = await ApiCon.get1('exameninformes/uno', this.idinforme)
      if (response.status === 200) {
        this.resultados = response.data
      } else { alert('Error en cargarResultados...') }
      this.loading = false
    },
    limpiar () {
      for (var i = 0; i < this.resultados.length; i++) {
        this.resultados[i].res = ''
      }
    },
    async consultarCambiosExamenes () {
      this.loading = true
      const response = await ApiCon.get1('exameninformes/detectarcambiosexamen', this.idinforme)
      if (response.status === 200) {
        this.hayCambiosExamenes = response.data
      } else { alert('Error en consultarCambiosExamenes...') }
      this.loading = false
    },
    async actualizarCambiosExamenes () {
      if (confirm('¿Está seguro que desea actualizar los campos base de exámenes de este informe?')) {
        const ins = {
          idinforme: this.idinforme
        }
        this.loading = true
        const response = await ApiCon.post('exameninformes/aplicarcambiosexamen', ins)
        if (response.status === 200) {
          alert('Han sido aplicados los cambios a los campos de exámenes que habían cambiado.')
          this.hayCambiosExamenes = false
          this.cargarResultados()
          this.cargarTabla('exameninformes/todo', 'cambiarTabla', 'cargarTablaInformes')
        } else { alert('error actualizarCambiosExamenes') }
        this.loading = false
      } else {}
    },
    async guardar (impreso, enviar, tipo) {
      if (this.valid === true) {
        this.loading = true
        const ins = {
          resultados: this.resultados,
          impreso: impreso,
          enviar: enviar,
          tipoimpenv: this.tipoimpenv,
          usuario: this.$session.get('usuario'),
          tipoinforme: this.tipoinforme
        }
        const response = await ApiCon.post('exameninformes/update', ins)
        if (response.status === 200) {
          if (this.tipoinforme === 'Documento') {
            this.guardarDoc()
          }
          this.cargarDatosBase()
          this.cargarTabla('exameninformes/todo', 'cambiarTabla', 'cargarTablaInformes')
          alert('Informe guardado.')
          if (tipo === 'imprimir') {
            this.impresionDirecta = true
          } else if (tipo === 'enviar') {
            this.enviarEmailDirecto = true
          }
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      } else { alert('Complete todos los resultados.') }
    },
    async guardarDoc (impreso, enviar, tipo) {
      if (this.valid === true) {
        this.loading = true
        const formData = new FormData()
        formData.append('notas', this.nombredocumento)
        formData.append('idinforme', this.idinforme)
        formData.append('usuario', this.$session.get('usuario'))
        const response = await ApiCon.post('exameninformes/guardardoc', formData)
        if (response.status === 200) {
          // se guarda el documento
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      } else { alert('Complete todos los resultados.') }
    },
    guardarSolo () {
      this.guardar(false, false, 'solo')
    },
    guardarImprimir () {
      this.impresionDirecta = false
      this.guardar(true, false, 'imprimir')
    },
    guardarEmail () {
      this.enviarEmailDirecto = false
      this.guardar(false, true, 'enviar')
    },
    guardarYSiguiente () {
      this.idinforme = this.idsInformeOrden[this.posInformesOrden]
      this.guardar(false, false, 'solo')
      if (this.valid === true) {
        if (this.idsInformeOrden.length > 0 & this.idsInformeOrden.length > this.posInformesOrden) {
          this.cargarResultados()
          this.cargarDatosBase()
          this.consultarCambiosExamenes()
          this.posInformesOrden++
        }
        if (this.idsInformeOrden.length === this.posInformesOrden) {
          this.disableSiguiente = true
        }
      }
    },
    async agregarExamen () {
      if (this.examen !== '' & this.examen !== null) {
        this.loading = true
        var examenes = []
        examenes.push(this.examen)
        const ins = {
          idbase_informe: this.idinforme,
          examenes: examenes,
          usuario: this.$session.get('usuario')
        }
        const response = await ApiCon.post('exameninformes/agregarexamen', ins)
        if (response.status === 200) {
          this.dialogExamenes = false
          this.cargarResultados()
        } else { alert('Ha ocurrido un error con el guardado de datos') }
        this.loading = false
      } else { alert('Debe seleccionar un examen.') }
    },
    preQuitarCampo (idexameninforme) {
      if (confirm('¿Está seguro que desea eliminar este campo del informe?') === true) {
        this.quitarExamen(idexameninforme)
      }
    },
    async quitarExamen (idexameninforme) {
      this.loading = true
      const ins = {
        idexamen_informe: idexameninforme
      }
      const response = await ApiCon.post('exameninformes/quitarexamen', ins)
      if (response.status === 200) {
        this.cargarResultados()
      } else { alert('Ha ocurrido un error con la eliminación de datos') }
      this.loading = false
    },
    volver () {
      this.$router.push('informesexamenes')
    },
    cargarDatos () {
      this.idinforme = this.$store.state.ids
      this.cargarDatosBase()
      this.cargarResultados()
      this.cargarInformesOrden()
      this.consultarCambiosExamenes()
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogIE
      },
      set: function (newValue) {
        this.$emit('update:dialogIE', newValue)
      }
    },
    datosBaseInput: {
      get: function () {
        return this.datosBase
      },
      set: function (newValue) {
        this.$emit('update:datosBase', newValue)
      }
    }
  },
  watch: {
    dialogIE () {
      if (this.dialogIE === false) {
        this.reset()
      } else {
        this.cargarDatos()
      }
    }
  }
}
</script>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
