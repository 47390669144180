<template>
  <DialogOpciones
    :dialog.sync="dialogInput"
    :opciones="opciones"
    :width="400"
    @clic-boton="clicBoton"
  />
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'
import DialogOpciones from '@/components/dialogopciones.vue'

export default {
  data: () => ({
    opciones: [
      { id: 1, nombre: 'ENVIAR SOLO', color: 'primary', accion: 'emailInformesSolo' },
      { id: 2, nombre: 'ENVIAR ORDEN', color: 'primary', accion: 'emailInformesOrden' }
    ]
  }),
  mixins: [
    Globales
  ],
  components: {
    DialogOpciones
  },
  props: ['idinforme', 'dialogEI', 'enviardirecto'],
  methods: {
    async emailInformes (tipo) {
      this.dialogInput = false
      alert('Informe(s) enviado(s) al email del paciente.')
      const ins = {
        idinforme: this.idinformeInput,
        tipoimpenv: tipo,
        usuario: this.$session.get('usuario')
      }
      const response = await ApiCon.post('exameninformes/emailinformes', ins)
      if (response.status === 200) {
      } else { alert('Ha ocurrido un error con el envío del informe') }
    },
    clicBoton (accion) {
      if (accion === 'emailInformesSolo') {
        this.emailInformes('solo')
      } else if (accion === 'emailInformesOrden') {
        this.emailInformes('orden')
      }
    }
  },
  created () {
    //
  },
  computed: {
    idinformeInput: {
      get: function () {
        return this.idinforme
      },
      set: function (newValue) {
        this.$emit('update:idinforme', newValue)
      }
    },
    dialogInput: {
      get: function () {
        return this.dialogEI
      },
      set: function (newValue) {
        this.$emit('update:dialogEI', newValue)
      }
    },
    enviardirectoInput: {
      get: function () {
        return this.enviardirecto
      },
      set: function (newValue) {
        this.$emit('update:enviardirecto', newValue)
      }
    }
  },
  watch: {
    dialogEI () {
      if (this.dialogEI === true) {
        //
      }
    },
    enviardirecto () {
      if (this.enviardirecto === true) {
        this.emailInformes('solo')
      }
    }
  }
}
</script>
