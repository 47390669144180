<template>
  <v-dialog
      v-model="dialogInput"
      persistent
      :retain-focus="false"
    >
      <v-card class="mt-1" style="border-radius: 0px; background-color: cornflowerblue;">
        <v-card-actions>
          <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
          <v-btn
            color="error"
            dark
            fab
            small
            @click="dialogInput = false"
          >
          <v-icon dark>
            mdi-close
          </v-icon>
          </v-btn>
        </v-row>
        </v-card-actions>
      </v-card>
    <v-row no-gutters wrap align="center">
      <v-col
          cols="12"
          sm="12"
        >
          <v-card
            class="mx-auto"
            outlined
            tile
            elevation="5"
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="3"
                  class="text-center"
                >
                  <p class="display-1 primary--text pt-5 pb-5 ml-1" style="border: solid 1px">LOGO</p>
                </v-col>
                <v-col
                  cols="12"
                  sm="9"
                >
                <p class="primary--text ml-5 title" style="margin-bottom: -5px;">{{datosbase[0].razon_social}}</p>
                <p class="primary--text ml-5">{{datosbase[0].direccion_central}}</p>
                </v-col>
              </v-row>
              <v-row class="title mx-1 mb-1" style="border: 1px solid #1976d2; margin-top: -20px;">
                <v-col
                  cols="12"
                  sm="4"
                >
                  <p class="primary--text" style="display: inline;">PACIENTE:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].paciente}}</p>
                  <br>
                  <p class="primary--text" style="display: inline;">EDAD:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].edad}}</p>
                  <br>
                  <p class="primary--text" style="display: inline;">RUT:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].rut}}</p>
                  <br>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <p class="primary--text" style="display: inline;">SOLICITANTE:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].solicitante}}</p>
                  <br>
                  <p class="primary--text" style="display: inline;">N° ORDEN:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].norden}}</p>
                  <br>
                  <p class="primary--text" style="display: inline;">FECHA INGRESO:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].fecha_ingreso}}</p>
                  <br>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <p class="primary--text" style="display: inline;">FECHA IMPRESIÓN:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].fecha_impresion}}</p>
                  <br>
                  <p class="primary--text" style="display: inline;">TOMA MUESTRAS:&nbsp;</p>
                  <p class="black--text" style="display: inline;">{{datosbase[0].tmuestra}}</p>
                  <br>
                </v-col>
              </v-row>
              <v-row class="headline primary--text mx-1 pt-1" style="border: 1px solid #1976d2;" justify="center">
                {{datosexamen[0].nombre_examen}}
              </v-row>
              <v-row class="title primary--text" justify="center">
                (Método: {{datosexamen[0].metodo}}) - (Muestra: {{datosexamen[0].muestra}})
              </v-row>
              <v-row class="mb-5" justify="center">
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-data-table
                    :headers="headers"
                    :items="resultados"
                    :mobile-breakpoint='0'
                    :hide-default-footer="true"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="primary--text mx-1 title mt-12" justify="end">
                <p>{{datosexamen[0].tecnologo}}</p>
              </v-row>
              <v-row class="primary--text mx-1 subtitle-1" justify="end" style="margin-top: -15px;">
                <p>Tecnólogo Médico</p>
              </v-row>
              <v-row class="black--text mx-1 mb-5 subtitle-1" justify="center" style="margin-top: -15px;">
                <p><i>El presente informe no constituye diagnóstico. El paciente debe consultar a su médico.</i></p>
              </v-row>
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import Globales from '@/mixins/globales.vue'
export default {
  data: () => ({
    datosbase: [
      { razon_social: 'AFLAB LTDA', direccion_central: 'Datos dirección central', paciente: 'Bastian Fernández Palma', edad: '35', rut: '16.141.741-6', solicitante: 'Dr. Juan Gonzalez', norden: '111', fecha_ingreso: '18-04-2021', fecha_impresion: '19-04-2021', tmuestra: 'Diagnomed' }
    ],
    datosexamen: [
      { nombre_examen: 'Perfil Bioquímico', metodo: 'varios', muestra: 'Sangre', tecnologo: 'Arturo Fernández Espinoza' }
    ],
    resultados: [
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' },
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' },
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' },
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' },
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' },
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' },
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' },
      { parametro: 'xxxxxx', resultado: 'xxxxxxx', resultado_ant: 'xxxxxxx', umedida: 'xxxxxxx', referencia: 'xxxxxxxx', metodo: 'xxxxxxxx' }
    ],
    // headers en principio es vacio y en load se carga dependiendo si es plantilla base o custom.
    headers: [
      { text: 'Parámetro', align: 'start', value: 'parametro' },
      { text: 'Resultado', align: 'start', value: 'resultado' },
      { text: 'Resultado anterior', align: 'start', value: 'resultado_ant' },
      { text: 'U. Medida', align: 'start', value: 'umedida' },
      { text: 'Referencia', align: 'start', value: 'referencia' },
      { text: 'Método', align: 'start', value: 'metodo' }
    ]
  }),
  mixins: [
    Globales
  ],
  props: ['dialogVI'],
  methods: {
    volver () {
      this.$router.push('informesexamenes')
    },
    cargarDatos () {
      this.enSesion('sitio')
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogVI
      },
      set: function (newValue) {
        this.$emit('update:dialogVI', newValue)
      }
    }
  },
  watch: {
    dialogVI () {
      if (this.dialogVI === false) {
        //
      }
    }
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: #1976d2;
  }
</style>
