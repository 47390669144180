<template>
  <div id="informesexamenes">
    <MenuBase
      :tipo="'informesexamenes'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-clipboard-text'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
      :subtitulo="subtitulo"
    />
    <VerInforme :dialogVI.sync="dialogVerInforme"/>
    <InformarExamen :dialogIE.sync="dialogInformarExamen" :datosBase.sync="datosBaseInforme"/>
    <DialogVentana :dialog.sync="dialogDetalles" :loading.sync="loadingdetalles">
      <FichaDatos
        :titulo="'DETALLES DEL INFORME'"
        :datos="detallesInforme1"
        :datos2="detallesInforme2"
      />
    </DialogVentana>
    <VerImprimirInforme :idinforme.sync="this.$store.state.ids" :dialogVI.sync="dialogVerImprimir"/>
    <EmailInformes :idinforme.sync="this.$store.state.ids" :dialogEI.sync="dialogEmailInformes"/>
    <DialogSimple
      :dialog.sync="dialogAnular"
      :titulo="'REGISTRE LA RAZÓN DE LA ANULACIÓN'"
      :width="450"
    >
      <v-row no-gutters class="text-center" wrap align="center">
        <v-col
          cols="12"
          sm="12"
        >
          <v-form ref="formanular" v-model="valid">
            <v-textarea
              v-model="notasAnular"
              auto-grow
              filled
              label="Notas"
              rows="3"
              counter
              maxlength="100"
              :rules="[requerido]"
            ></v-textarea>
            <v-row align="center" justify="center">
              <v-btn
                color="error"
                class="mt-3"
                @click="anularInforme"
                :disabled="!valid"
              >
              ANULAR
              </v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </DialogSimple>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import VerInforme from '@/views/InformesExamen/verinformeexamen.vue'
import InformarExamen from '@/views/InformesExamen/informarexamen.vue'
import ApiCon from '@/logic/apicon.js'
import VerImprimirInforme from '@/components/verimprimirinforme.vue'
import EmailInformes from '@/components/emailinformes.vue'
import DialogSimple from '@/components/dialogsimple.vue'
import DialogVentana from '@/components/dialogventana.vue'
import FichaDatos from '@/components/fichadatos.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    VerInforme,
    InformarExamen,
    VerImprimirInforme,
    EmailInformes,
    DialogSimple,
    DialogVentana,
    FichaDatos
  },
  data: () => ({
    headers: [],
    botones: [],
    opciones: [],
    titulo: 'Informes de Exámenes',
    tituloOpciones: 'Opciones Informe N° ',
    campoOpcion: '',
    alineaHeaders: 'start',
    dialogVerInforme: false,
    dialogInformarExamen: false,
    datosBaseInforme: [],
    dialogDetalles: false,
    detallesInforme1: [],
    detallesInforme2: [],
    dialogVerImprimir: false,
    dialogEmailInformes: false,
    loading: false,
    dialogAnular: false,
    notasAnular: '',
    valid: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    subtitulo: '',
    loadingdetalles: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'N°', align: 'start', value: 'ninforme', width: '120px' },
        { text: 'ORDEN', align: 'start', value: 'norden', width: '120px' },
        { text: 'NOMBRE', align: 'start', value: 'nombre_informe', width: '250px' },
        { text: 'FECHA', align: 'start', value: 'fecha_creacion', width: '120px' },
        { text: 'RUT', align: 'start', value: 'rut', width: '150px' },
        { text: 'PACIENTE', align: 'start', value: 'paciente', width: '250px' },
        { text: 'ESTADO', align: 'start', value: 'estado', width: '120px' },
        { text: 'ENTREGA', align: 'start', value: 'entrega', width: '80px' }
      ]
    },
    cargarOpciones (estado) {
      this.opciones = []
      if (this.returnPrilegio('informes-menutabla-detalles') === true) {
        this.opciones.push({ id: 1, nombre: 'DETALLES INFORME', color: 'primary', accion: 'detallesInforme' })
      }
      if (estado === 'Informar' | estado === 'Informado') {
        if (this.returnPrilegio('informes-menutabla-informareditar') === true) {
          this.opciones.push({ id: 2, nombre: 'INFORMAR o EDITAR', color: 'primary', accion: 'informarEditarInforme' })
        }
      }
      if (estado === 'Informado') {
        if (this.returnPrilegio('informes-menutabla-verinforme') === true) {
          this.opciones.push({ id: 3, nombre: 'VER INFORME/IMPRIMIR', color: 'primary', accion: 'verInforme' })
        }
        if (this.returnPrilegio('informes-menutabla-enviar') === true) {
          this.opciones.push({ id: 4, nombre: 'ENVIAR POR EMAIL', color: 'primary', accion: 'enviarMailInforme' })
        }
      }
      if (this.returnPrilegio('informes-menutabla-anular') === true & estado !== 'Anulado' & estado !== 'Informado') {
        this.opciones.push({ id: 5, nombre: 'ANULAR INFORME', color: 'error', accion: 'anularInforme' })
      }
    },
    clicFila (fila) {
      this.campoOpcion = fila.ninforme + ': ' + fila.nombre_informe + ' de ' + fila.paciente + ' - ' + fila.fecha_creacion
      this.$store.commit('cambiarID', fila.id)
      this.cargarOpciones(fila.estado)
      this.datosBaseInforme = []
      this.datosBaseInforme.push({ paciente: fila.paciente, examen: fila.nombre_informe, estado: fila.estado, fcreacion: fila.fecha_creacion, norden: fila.norden })
    },
    clicBoton (accion) {
      if (accion === 'verInforme') {
        // this.dialogVerInforme = true
        this.dialogVerImprimir = true
      } else if (accion === 'informarEditarInforme') {
        this.dialogInformarExamen = true
      } else if (accion === 'detallesInforme') {
        this.cargarDetallesInforme()
        this.dialogDetalles = true
      } else if (accion === 'anularInforme') {
        this.dialogAnular = true
      } else if (accion === 'enviarMailInforme') {
        this.dialogEmailInformes = true
      } else if (accion === 'confirmarEnvioMultiEmail') {
        alert('Enviando informes por correo')
      }
    },
    async cargarDetallesInforme () {
      this.detallesInforme1 = []
      this.detallesInforme2 = []
      this.loadingdetalles = true
      const response = await ApiCon.get1('exameninformes/detallesinforme', this.$store.state.ids)
      if (response.status === 200) {
        this.detallesInforme1.push({ campo: 'N° INFORME', resultado: response.data.ninforme })
        this.detallesInforme1.push({ campo: 'N° ORDEN', resultado: response.data.norden })
        this.detallesInforme1.push({ campo: 'NOMBRE', resultado: response.data.nombre_informe })
        this.detallesInforme1.push({ campo: 'RUT PACIENTE', resultado: response.data.rut })
        this.detallesInforme1.push({ campo: 'PACIENTE', resultado: response.data.paciente })
        this.detallesInforme1.push({ campo: 'ESTADO', resultado: response.data.estado })
        this.detallesInforme1.push({ campo: 'FECHA ORDEN', resultado: response.data.fecha_orden })
        this.detallesInforme1.push({ campo: 'SUCURSAL ORDEN', resultado: response.data.sucursal_orden })
        this.detallesInforme1.push({ campo: 'FECHA TOMA', resultado: response.data.fecha_toma })
        this.detallesInforme2.push({ campo: 'SUCURSAL MUESTRA', resultado: response.data.sucursal_muestra })
        this.detallesInforme2.push({ campo: 'FECHA INFORME', resultado: response.data.fecha_informe })
        this.detallesInforme2.push({ campo: 'SUCURSAL INFORME', resultado: response.data.sucursal_informe })
        this.detallesInforme2.push({ campo: 'FECHA IMPRESIÓN', resultado: response.data.fecha_impresion })
        this.detallesInforme2.push({ campo: 'TIPO IMPRESIÓN', resultado: response.data.tipo_impresion })
        this.detallesInforme2.push({ campo: 'TECNÓLOGO', resultado: response.data.tecnologo })
        this.detallesInforme2.push({ campo: 'SOLICITANTE', resultado: response.data.solicitante })
        this.detallesInforme2.push({ campo: 'NOTAS ORDEN', resultado: response.data.notas })
        this.detallesInforme2.push({ campo: 'NOTAS INFORME', resultado: response.data.notas_informe })
      } else { alert('error cargarDetallesInforme') }
      this.loadingdetalles = false
    },
    async cargarItems () {
      if (this.$store.state.modoTablaInforme === '') {
        this.cargarTabla('exameninformes/todo', 'cambiarTabla', 'cargarTablaInformes')
      } else if (this.$store.state.modoTablaInforme === 'pacienteInformar') {
        const response = await ApiCon.get2('exameninformes/porpaciente', this.$store.state.ids, 'Informar')
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.$store.commit('cambiarTabla', response.data)
            this.subtitulo = 'Por Informar de: ' + response.data[0].paciente
          } else {
            alert('No existen informes del paciente para informar')
            this.$store.commit('cambiarModoTablaInforme', '')
            this.$router.push('/pacientes')
          }
        } else { alert('error pacienteInformar') }
      } else if (this.$store.state.modoTablaInforme === 'pacienteHistorico') {
        const response = await ApiCon.get2('exameninformes/porpaciente', this.$store.state.ids, 'Informado')
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.$store.commit('cambiarTabla', response.data)
            this.subtitulo = 'Históricos de: ' + response.data[0].paciente
          } else {
            alert('No existe informes históricos del paciente seleccionado')
            this.$store.commit('cambiarModoTablaInforme', '')
            this.$router.push('/pacientes')
          }
        } else { alert('error pacienteHistorico') }
      }
      this.$store.commit('cambiarModoTablaInforme', '')
    },
    async anularInforme () {
      if (this.valid === true) {
        const ins = {
          idinforme: this.$store.state.ids,
          notas: this.notasAnular
        }
        this.loading = true
        const response = await ApiCon.post('exameninformes/anularinforme', ins)
        if (response.status === 200) {
          alert('Informe anulado.')
          this.cargarItems()
          this.dialogAnular = false
          this.cargarOpciones('Anulado')
        } else { alert('mensaje error') }
        this.loading = false
      }
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('informes') === true) {
          this.interfaz()
          this.cargarItems()
          this.datosBaseInforme.push({ paciente: '', examen: '', estado: '', fcreacion: '', norden: '' })
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  },
  watch: {
    dialogAnular () {
      if (this.dialogAnular === false) {
        this.$refs.formanular.reset()
      }
    }
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
