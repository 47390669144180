<template>
  <DialogOpciones
    :dialog.sync="dialogInput"
    :opciones="opciones"
    :width="400"
    @clic-boton="clicBoton"
  />
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'
import DialogOpciones from '@/components/dialogopciones.vue'

export default {
  data: () => ({
    opciones: [
      { id: 1, nombre: 'IMPRIMIR SOLO', color: 'primary', accion: 'imprimirInformesSolo' },
      { id: 2, nombre: 'IMPRIMIR ORDEN', color: 'primary', accion: 'imprimirInformesOrden' }
    ]
  }),
  mixins: [
    Globales
  ],
  components: {
    DialogOpciones
  },
  props: ['idinforme', 'dialogVI', 'impdirecto'],
  methods: {
    async imprimirInformes (tipo) {
      const ins = {
        idinforme: this.idinformeInput,
        tipoimpenv: tipo,
        usuario: this.$session.get('usuario')
      }
      this.loading = true
      const response = await ApiCon.postDownload('exameninformes/imprimirinformes', ins)
      if (response.status === 200) {
        this.procesaPDF(response.data, 'informe')
      } else { alert('Ha ocurrido un error con la generación del informe') }
      this.loading = false
    },
    clicBoton (accion) {
      if (accion === 'imprimirInformesSolo') {
        this.dialogInput = false
        this.imprimirInformes('solo')
      } else if (accion === 'imprimirInformesOrden') {
        this.dialogInput = false
        alert('El informe será procesado a PDF y aparecerá dentro de poco.')
        this.imprimirInformes('orden')
      }
    }
  },
  created () {
    //
  },
  computed: {
    idinformeInput: {
      get: function () {
        return this.idinforme
      },
      set: function (newValue) {
        this.$emit('update:idinforme', newValue)
      }
    },
    dialogInput: {
      get: function () {
        return this.dialogVI
      },
      set: function (newValue) {
        this.$emit('update:dialogVI', newValue)
      }
    },
    impdirectoInput: {
      get: function () {
        return this.impdirecto
      },
      set: function (newValue) {
        this.$emit('update:impdirecto', newValue)
      }
    }
  },
  watch: {
    dialogVI () {
      if (this.dialogVI === true) {
        //
      }
    },
    impdirecto () {
      if (this.impdirecto === true) {
        this.imprimirInformes('solo')
      }
    }
  }
}
</script>
